<template>
  <div class="party-ctn">
    <b-row v-if="party">
      <b-col sm="12" md="6" class="text-center">
        <img class="poster" :src="getImgUrl(party.src)" />
      </b-col>
      <b-col style="text-align: -webkit-center;" sm="12" md="6">
        <PostersCarousel disableButton :posters="party.photos"
          :requireCtx="require.context('../assets/img/parties', false, /\.jpeg$/)" :width="600" :height="400">
        </PostersCarousel>
        <b-btn href="https://www.facebook.com/partyrevive.eventos/photos_by">Ver todas las fotos</b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import parties from '@/assets/parties.json'
import PostersCarousel from '@/components/PostersCarousel.vue';

export default {
  name: 'PartyPhotos',
  components: {
    PostersCarousel
  },
  data () {
    return {
      party: null
    }
  },
  methods: {
    getImgUrl(src) {
      var images = require.context('../assets/', false, /\.jpeg$/)
      return images('./' + src)
    },
    getPartyPhotos () {
      return this.currentParty.photos
    }
  },
  created () {
    this.party = parties.parties.find((item) => item.name === this.$route.params.party)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/global.scss';

.poster {
  width: 65%;
}

.party-ctn {
  height: 100vh;
  @include desktop {
    padding-top: 10rem;
  }

  @include mobile {
    padding-top: 6rem;
  }
  
}
</style>